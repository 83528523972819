import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import { BreadCrumb } from "../components/BreadCrumb/BreadCrumb"
import { Layout } from "../components/Layout"

const Wrapper = styled.div`
  max-width: 1180px;
  margin: 0 auto;
  padding: 20px;
`

const ContentWrapper = styled.div`
  display: block;

  @medio (min-width: 992px) {
    display: flex;
  }
`

const PageContent = styled.div`
  margin-top: 20px;

  h1 {
    padding: 0;
    font-size: 1.8em;
  }

  h2 {
    padding: 0;
    font-size: 1.5em;
    text-align: left;
  }

  h1,
  h2,
  div {
    font-family: ${({ theme }) => theme.fonts[0]};

    p,
    ul,
    li {
      font-family: ${({ theme }) => theme.fonts[0]};
    }

    label {
      span {
        input,
        textarea {
          padding: 15px;
        }
      }
    }

    button,
    [type="button"],
    [type="reset"],
    [type="submit"] {
      font-family: ${({ theme }) => theme.fonts[0]};
      font-size: 0.99em;
      padding: 15px 14px 9px 14px;
      background-color: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.white};
      font-weight: bold;
      border: 0;
      border-radius: 8px;
      box-shadow: 0 8px 20px -6px rgba(black, 0.3);
    }
  }
`

const PageTemplate = ({ data }) => {
  console.log(data)

  const featuredImage = data.wpPage.featuredImage
  return (
    <Layout>
      <Wrapper>
        <BreadCrumb
          parent={data.wpPage.wpParent ? data.wpPage.wpParent.node : null}
        />
        <ContentWrapper>
          <PageContent>
            <h1 dangerouslySetInnerHTML={{ __html: data.wpPage.title }} />
            <div dangerouslySetInnerHTML={{ __html: data.wpPage.content }} />
          </PageContent>
        </ContentWrapper>
      </Wrapper>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      content
      status
      featuredImage {
        node {
          id
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920, placeholder: TRACED_SVG)
            }
          }
        }
      }
      wpChildren {
        nodes {
          ... on WpPage {
            id
            uri
            title
          }
        }
      }
      wpParent {
        node {
          ... on WpPage {
            id
            uri
            title
            wpChildren {
              nodes {
                ... on WpPage {
                  id
                  uri
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`
